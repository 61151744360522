import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {UserState} from './user.type';

const initialState: UserState = {
    address: '',
    currencyAmount: 0,
    selectedCurrency: '',
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateAddressValue: (state:UserState, action: PayloadAction<string>) => {
            state.address = action.payload;
        },
        updateCurrencyAmount: (state:UserState, action: PayloadAction<number>) => {
            state.currencyAmount = action.payload;
        },
        updateSelectedCurrency: (state:UserState, action: PayloadAction<string>) => {
            state.selectedCurrency = action.payload;
        },
        updateUserInfo: (state:UserState, action: PayloadAction<UserState>) => {
            state.address = action.payload.address;
            state.currencyAmount = action.payload.currencyAmount;
        }
    },
});

export const {updateAddressValue, updateCurrencyAmount, updateSelectedCurrency, updateUserInfo} = userSlice.actions;

export default userSlice.reducer;