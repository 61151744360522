import React, {FC, useState, forwardRef, useEffect} from 'react';
import {Container, Group, Select, Stack, Text, useMantineTheme} from '@mantine/core';
import {ChevronDown as ChevronDownIcon} from 'tabler-icons-react';
import {RootState, useAppDispatch, useAppSelector} from '../../store';

import {WalletInfo, Status, Stake, Stats} from '../index';

import {updateSelectedCurrency} from '../../store/slices/user';

import IMain from './Main.type';

const selectData = [
    {
        label: 'ATOM',
        value: 'atom',
        address: 'Cosmos Hub',
        percent: 4.67,
    },
    {
        label: 'SCRT',
        value: 'scrt',
        address: 'Secret',
        percent: 8.73,
    },
    {
        label: 'STRD',
        value: 'strd',
        address: 'Stride',
        percent: 12.23,
    },
    {
        label: 'JUNO',
        value: 'juno',
        address: 'Juno',
        percent: 7.23,
    },
];

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    label: string;
    address: string;
    percent: number | string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({label, address, percent, ...others }: ItemProps, ref) => (
        <div ref={ref} {...others}>
            <div className="select-option-item">
                <Text size="lg" weight={700}>{label}</Text>
                <Text size="lg">
                    &nbsp;{`· ${address} · ${percent}% APY`}
                </Text>
            </div>
        </div>
    )
);

const Main: FC<IMain> = () => {
    const theme = useMantineTheme();

    const dispatch = useAppDispatch();
    const userInfo = useAppSelector((state: RootState) => state.user);

    useEffect(() => {
        dispatch(updateSelectedCurrency(selectData[0].value));
    }, []);

    const currentObject = selectData.find(item => item.value === userInfo.selectedCurrency);

    return (
        <Container size={560} p={0}>
            <Group position="apart" className="main-group">
                {/* @ts-ignore */}
                <div className="main-select" style={{width: `${(currentObject?.label.length) * 55 + 40}px`}}>
                    <Select
                        size="xl"
                        itemComponent={SelectItem}
                        data={selectData}
                        value={userInfo.selectedCurrency}
                        onChange={(value) => dispatch(updateSelectedCurrency(value || ''))}
                        maxDropdownHeight={400}
                        styles={{
                            dropdown: {
                                width: '200% !important',
                                overflow: 'visible',
                            },
                            item: {
                                width: '430px',
                            },
                            selected: {
                                backgroundColor: theme.colors.gray[0],
                                color: theme.colors.gray[8],
                            }
                        }}
                        withinPortal={false}
                        rightSection={
                            <ChevronDownIcon
                                size={32}
                                strokeWidth={4}
                                color={"#000"}
                            />
                        }
                        rightSectionWidth={32}
                        onDropdownOpen={() => {
                            // @ts-ignore
                            document.getElementsByClassName('mantine-Select-wrapper')[0].style.backgroundColor = theme.colors.cyan[1];
                        }}
                        onDropdownClose={() => {
                            // @ts-ignore
                            document.getElementsByClassName('mantine-Select-wrapper')[0].style.backgroundColor = 'transparent';
                        }}
                    />
                </div>
                <div style={{padding: '42px 0'}}>
                    <Text
                        size="xs"
                        weight={700}
                        sx={{lineHeight: '155%'}}
                    >
                        APY
                    </Text>
                    <Text
                        sx={{
                            fontSize: '26px',
                            lineHeight: '135%',
                        }}
                        weight={700}
                    >
                        {`${currentObject?.percent || 0}%`}
                    </Text>
                </div>
            </Group>

            <Stack spacing="lg">
                <WalletInfo />
                <Status />
                <Stake />
                <Stats />
            </Stack>
        </Container>
    );
};

export default Main;