import React, {FC} from 'react';
import {Space, Text} from '@mantine/core';

import IModalStepContent from './ModalStepContent.type';

const ModalStepContent: FC<IModalStepContent> = ({label, description, additionalComponents}) => {
    return (
        <div>
            <Text size="lg" weight={700}>{label}</Text>
            <Space h="xs" />
            <Text size="sm">{description}</Text>
            { additionalComponents && additionalComponents()}
        </div>
    );
};

export default ModalStepContent;