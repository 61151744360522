export const AccordionOptions = () => {
    return {
        item: {
            border: 'none',
            '& button': {
                padding: '0'
            }
        },
        label: {

        },
        control: {
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'transparent !important',
            }
        },
        content: {
            '& > div:first-of-type': {
                padding: '24px 0 0'
            }
        }
    }
};