import React, {FC, useState} from 'react';
import {ActionIcon, Button, Modal, Space, Stepper, Text, useMantineTheme} from '@mantine/core';
import {X as CloseIcon} from 'tabler-icons-react';

import {ModalStepContent} from '../index';

import {RootState, useAppSelector} from '../../store';
import ILiquidStakeModal from './LiquidStakeModal.type';
import {LiquidStakeModalOptions} from '../../assets/constants/LiquidStakeModalOptions';

const LiquidStakeModal: FC<ILiquidStakeModal> = ({isOpened, closeModal}) => {
    const theme = useMantineTheme();
    const userInfo = useAppSelector((state: RootState) => state.user);
    const [active, setActive] = useState<number>(4);
    const currentContent = LiquidStakeModalOptions(userInfo.selectedCurrency, closeModal).filter(item => item.id === active)[0];

    const nextStep = () => setActive((current) => (current < 4 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

    return (
        <Modal
            opened={isOpened}
            withCloseButton={false}
            title={
                <div>
                    <Text size="sm" weight={600} transform="uppercase" color={theme.colors.gray[7]}>Liquid staking</Text>
                    <Text
                        weight={700}
                        sx={{
                            fontSize: '22px'
                        }}
                    >
                        {`${'83.274'} ${userInfo.selectedCurrency.toUpperCase()}`}
                    </Text>
                    <Space h="xl" />
                    <Stepper
                        active={active}
                        onStepClick={setActive}
                        breakpoint="sm"
                        orientation="vertical"
                        color="cyan"
                    >
                        <Stepper.Step label="Approve transfer in wallet" />
                        <Stepper.Step label={`Transfer ${userInfo.selectedCurrency.toUpperCase()} to Stride`} />
                        <Stepper.Step label="Approve staking in wallet" />
                        <Stepper.Step label={`Stake ${userInfo.selectedCurrency.toUpperCase()}, get ${'st' + userInfo.selectedCurrency.toUpperCase()}`} />
                    </Stepper>
                </div>
            }
            onClose={() => closeModal(false)}
            padding={0}
            radius="md"
            centered
            closeOnEscape={true}
            closeOnClickOutside={true}
            size="xl"
            styles={{
                modal: {
                    display: 'flex',
                },
                header: {
                    backgroundColor: theme.colors.gray[0],
                    width: '80%',
                    margin: 0,
                    padding: '32px',
                    borderRadius: '8px 0 0 8px'
                },
                body: {
                    width: '100%',
                    padding: '0 64px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    position: 'relative',
                }
            }}
        >
            <div>
                <ActionIcon
                    sx={{
                        position: 'absolute',
                        top: '32px',
                        right: '32px',
                    }}
                    onClick={() => closeModal(false)}
                >
                    <CloseIcon
                        size={24}
                        strokeWidth={2}
                        color={theme.colors.gray[8]}
                    />
                </ActionIcon>
                { <ModalStepContent
                    label={currentContent.label}
                    description={currentContent.description}
                    additionalComponents={currentContent.additionalComponents}
                /> }
            </div>
        </Modal>
    );
};

export default LiquidStakeModal;