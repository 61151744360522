import React, {FC, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Badge, Button, Group, Text, Menu, useMantineColorScheme, useMantineTheme} from '@mantine/core';
import {showNotification} from '@mantine/notifications';
import {Bech32Address} from '@keplr-wallet/cosmos';
import {useWalletManager} from 'cosmodal';
import {Wallet as WalletIcon} from 'tabler-icons-react';

import {ReactComponent as Logo} from '../../assets/icons/logo.svg';

import {RootState, useAppDispatch, useAppSelector} from '../../store';
import {updateUserInfo} from '../../store/slices/user';
import {connectWallet, signOut} from '../../utils/helpers';
import {EmbedChainInfos} from '../../config';
import IHeader from './Header.type';

const AUTO_CONNECT_WALLET_KEY = 'auto_connect_wallet';

const Header: FC<IHeader> = () => {
    const theme = useMantineTheme();

    const dispatch = useAppDispatch();
    const userInfo = useAppSelector((state: RootState) => state.user);

    // Wallet
    const {
        getWallet,
        clearLastUsedWallet,
        connectionType,
        setDefaultConnectionType,
    } = useWalletManager();

    const currentChain = EmbedChainInfos[0];

    useEffect(() => {
        if (userInfo.address) {
            showNotification({
                title: 'You are successfully logged in!',
                message: '',
                color: 'cyan'
            })
        }
    }, [userInfo.address]);

    // Store connected wallet type
    useEffect(() => {
        if (connectionType) {
            localStorage.setItem(AUTO_CONNECT_WALLET_KEY, connectionType);
        }
    }, [connectionType]);

    // Automatically connect wallet as stored type, even if page refreshed,
    useEffect(() => {
        const autoConnectionType = localStorage.getItem(AUTO_CONNECT_WALLET_KEY);
        if (autoConnectionType) {
            setDefaultConnectionType(autoConnectionType);
            connectWallet(getWallet, currentChain)
                .then((value) => dispatch(updateUserInfo(value)));
        }
    }, []);

    return (
        <Group position="apart">
            <div>
                <Link to="/">
                    <Logo fill="#222222" />
                </Link>
            </div>
            <div>
                { userInfo.address
                    ? <Group>
                        <Badge
                            color="cyan"
                            variant="dot"
                            sx={{border: 'none'}}
                        >
                            {currentChain.chainName}
                        </Badge>
                        <Menu control={
                            <Button
                                variant="outline"
                                color="dark"
                                leftIcon={
                                    <WalletIcon
                                        size={16}
                                        strokeWidth={1}
                                        color={theme.colors.gray[8]}
                                    />
                                }
                            >
                                {Bech32Address.shortenAddress(userInfo.address, 15)}
                            </Button>}
                        >
                            <Menu.Label>
                                {Bech32Address.shortenAddress(userInfo.address, 15)}
                            </Menu.Label>
                            <Menu.Item
                                onClick={() => {
                                    navigator.clipboard.writeText(userInfo.address)
                                        .then(() => {
                                            showNotification({
                                                title: 'Wallet address successfully copied to clipboard!',
                                                message: '',
                                                color: 'cyan',
                                            });
                                        });
                                }}
                            >
                                Copy wallet address
                            </Menu.Item>
                            <Menu.Item
                                onClick={() => {
                                    signOut(clearLastUsedWallet, setDefaultConnectionType)
                                    dispatch(updateUserInfo({address: '', currencyAmount: 0, selectedCurrency: ''}));
                                }}
                            >
                                Disconnect wallet
                            </Menu.Item>
                        </Menu>
                    </Group>
                    : <Button
                        variant="outline"
                        color="dark"
                        onClick={() => connectWallet(getWallet, currentChain).then((value) => dispatch(updateUserInfo(value)))}
                    >
                        <Text size="sm" weight={700}>Connect wallet</Text>
                    </Button>
                }
            </div>
        </Group>
    );
};

export default Header;