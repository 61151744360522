import {ChainInfo, Keplr} from '@keplr-wallet/types';
import {KeplrWalletConnectV1} from 'cosmodal';
import {assertIsDeliverTxSuccess, SigningStargateClient} from '@cosmjs/stargate';
import {showNotification} from "@mantine/notifications";

const AUTO_CONNECT_WALLET_KEY = 'auto_connect_wallet';

export const convertMicroDenomToDenom = (amount: number | string) => {
    if (typeof amount === 'string') {
        amount = Number(amount);
    }
    amount = amount / 1000000;
    return isNaN(amount) ? 0 : amount;
}

// @ts-ignore
export const connectWallet = async (getWallet , currentChain) => {
    const wallet: Keplr | KeplrWalletConnectV1 = await getWallet();
    await wallet.enable([currentChain.chainId]);
    const key = await wallet.getKey(currentChain.chainId);
    const offlineSigner = wallet.getOfflineSigner(currentChain.chainId);
    const client = await SigningStargateClient.connectWithSigner(
        currentChain.rpc,
        offlineSigner
    );

    const stakeCurrencyBalance = await client.getBalance(
        key.bech32Address,
        currentChain.stakeCurrency.coinMinimalDenom
    );

    return {
        address: key.bech32Address,
        currencyAmount: convertMicroDenomToDenom(stakeCurrencyBalance.amount),
        selectedCurrency: ''
    };
};

// @ts-ignore
export const signOut = (clearLastUsedWallet, setDefaultConnectionType) => {
    clearLastUsedWallet();
    setDefaultConnectionType(undefined);
    localStorage.removeItem(AUTO_CONNECT_WALLET_KEY);
    // For removing cached WalletConnect info
    localStorage.removeItem('walletconnect');
};

// To submit the form
export const submitForm = (value: number, currentChain: ChainInfo, getWallet: any) => {
    let recipient = 'stride1dlevxvgayk6m5u0wjvkfg202xlem3g22h37zn7';
    let amount = value;

    amount *= 1000000;
    amount = Math.floor(amount);

    return (async () => {
        const wallet: Keplr | KeplrWalletConnectV1 = await getWallet();
        await wallet.enable([currentChain.chainId]);
        const offlineSigner = wallet.getOfflineSigner(currentChain.chainId);
        const accounts = await offlineSigner.getAccounts();

        const client = await SigningStargateClient.connectWithSigner(
            currentChain.rpc,
            offlineSigner
        );

        const amountFinal = {
            denom: currentChain.stakeCurrency.coinMinimalDenom,
            amount: amount.toString(),
        };
        const fee = {
            amount: [{
                denom: currentChain.stakeCurrency.coinMinimalDenom,
                amount: '5000',
            }, ],
            gas: '200000',
        };

        const result = await client.sendTokens(accounts[0].address, recipient, [amountFinal], fee, "");
        assertIsDeliverTxSuccess(result);

        if (result.code !== undefined && result.code !== 0) {
            showNotification({
                title: 'Failed operation',
                // @ts-ignore
                message: `Failed to send tx: ${result.log || result.rawLog}`,
                color: 'red',
            });
        } else {
            const key = await wallet.getKey(currentChain.chainId);
            const updatedBalance = await client.getBalance(
                key.bech32Address,
                currentChain.stakeCurrency.coinMinimalDenom
            );

            showNotification({
                title: 'Successful operation!',
                message: `Succeed to send tx: ${result.transactionHash}`,
            });

            return convertMicroDenomToDenom(updatedBalance.amount);
        }
    })();
}