import React, {FC} from 'react';
import {ActionIcon, Group, Paper, Space, Text, Tooltip, useMantineColorScheme, useMantineTheme} from '@mantine/core';
import {InfoCircle as InfoCircleIcon} from 'tabler-icons-react';

import {RootState, useAppSelector} from '../../store';
import IStats from './Stats.type';

const Stats: FC<IStats> = () => {
    const theme = useMantineTheme();
    const userInfo = useAppSelector((state: RootState) => state.user);
    const tooltipPhrase: string = `After unstaking, tokens need to be unbonded before they are available to withdraw. If you don’t want to wait, you can trade ${'st' + userInfo.selectedCurrency.toUpperCase()} directly on a DEX like Osmosis.`;

    return (
        <Paper
            p="lg"
            withBorder
            radius="md"
            shadow="none"
        >
            <Text weight={600}>About {userInfo.selectedCurrency.toUpperCase()} on Stride</Text>
            <Space h="md" />
            <Group position="apart">
                <Text size="sm" color={theme.colors.gray[8]}>Total {userInfo.selectedCurrency.toUpperCase()} liquid staked</Text>
                <Text size="sm" color={theme.colors.gray[8]}>{'489,024.202'}</Text>
            </Group>
            <Space h="md" />
            <Group position="apart">
                <Text
                    size="sm"
                    color={theme.colors.gray[8]}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    Unbonding period
                    <Tooltip
                        label={tooltipPhrase}
                        wrapLines
                        width={220}
                        withArrow
                        transition="fade"
                        transitionDuration={200}
                    >
                        <ActionIcon>
                            <InfoCircleIcon
                                size={16}
                                strokeWidth={2}
                                color={theme.colors.gray[6]}
                            />
                        </ActionIcon>
                    </Tooltip>
                </Text>
                <Text size="sm" color={theme.colors.gray[8]}>21-24 days</Text>
            </Group>
            <Space h="md" />
            <Group position="apart">
                <Text size="sm" color={theme.colors.gray[8]}>Fees</Text>
                <Text size="sm" color={theme.colors.gray[8]}>???</Text>
            </Group>
        </Paper>
    );
};

export default Stats;