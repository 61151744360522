import React, {FC, useEffect} from 'react';
import {Accordion, ActionIcon, Space, Group, Paper, Text, useMantineColorScheme, Button, useMantineTheme, Tooltip} from '@mantine/core';
import {InfoCircle as InfoCircleIcon, Wallet as WalletIcon} from 'tabler-icons-react';
import {useWalletManager} from 'cosmodal';
import {Bech32Address} from '@keplr-wallet/cosmos';

import {AccordionOptions} from '../../assets/constants/AccordionOptions';
import {RootState, useAppDispatch, useAppSelector} from '../../store';
import {connectWallet} from '../../utils/helpers';
import {updateUserInfo} from '../../store/slices/user';
import {EmbedChainInfos} from '../../config';
import IWalletInfo from './WalletInfo.type';

const AUTO_CONNECT_WALLET_KEY = 'auto_connect_wallet';

const WalletInfo: FC<IWalletInfo> = () => {
    const theme = useMantineTheme();
    const dispatch = useAppDispatch();
    const userInfo = useAppSelector((state: RootState) => state.user);
    const tooltipPhrase: string = `Based on the amount of ${'st' + userInfo.selectedCurrency.toUpperCase()} currently in this wallet.`;

    const currentChain = EmbedChainInfos[0];

    const {
        getWallet,
        clearLastUsedWallet,
        connectionType,
        setDefaultConnectionType,
    } = useWalletManager();

    const handleConnectWallet = () => {
        connectWallet(getWallet, currentChain)
            .then((value) => dispatch(updateUserInfo(value)));
    }

    // Store connected wallet type
    useEffect(() => {
        if (connectionType) {
            localStorage.setItem(AUTO_CONNECT_WALLET_KEY, connectionType);
        }
    }, [connectionType]);

    // Automatically connect wallet as stored type, even if page refreshed,
    useEffect(() => {
        const autoConnectionType = localStorage.getItem(AUTO_CONNECT_WALLET_KEY);
        if (autoConnectionType) {
            setDefaultConnectionType(autoConnectionType);
            connectWallet(getWallet, currentChain)
                .then((value) => dispatch(updateUserInfo(value)));
        }
    }, []);

    return (
        <>
            { !userInfo.address &&
                <Paper p="lg" withBorder>
                    <Group spacing="md" align="flex-start">
                        <ActionIcon size="lg" variant="light">
                            <WalletIcon
                                size={16}
                                strokeWidth={1}
                                color={theme.colors.gray[6]}
                            />
                        </ActionIcon>
                        <div>
                            <Text size="md" weight={600}>Wallet</Text>
                            <Text size="sm">Not connected</Text>
                        </div>
                    </Group>
                    <Space h="lg" />
                    <Button
                        size="lg"
                        style={{ fontSize: '16px'}}
                        fullWidth
                        onClick={() => handleConnectWallet()}
                    >
                        Connect wallet
                    </Button>
                </Paper>
            }
            { userInfo.address &&
                <Paper p="lg" withBorder>
                    <Accordion
                        iconPosition="right"
                        transitionDuration={200}
                        // @ts-ignore
                        styles={AccordionOptions()}
                    >
                        <Accordion.Item label={
                            <Group spacing="md" align="flex-start">
                                <ActionIcon
                                    size="lg"
                                    sx={{
                                        backgroundColor: theme.colors.cyan[6],
                                        '&:hover': {
                                            backgroundColor: theme.colors.cyan[6],
                                        }
                                    }}
                                >
                                    <WalletIcon
                                        size={16}
                                        strokeWidth={1}
                                        color="white"
                                    />
                                </ActionIcon>
                                <div>
                                    <Text size="md" weight={600}>Wallet</Text>
                                    <Text size="sm">Connected ·&nbsp;{Bech32Address.shortenAddress(userInfo.address, 15)}</Text>
                                </div>
                            </Group>
                        } >
                            <div>
                                <Group position="apart">
                                    <Text size="md" weight={600} color={theme.colors.dark[7]}>Available</Text>
                                    <Text size="sm" weight={600} color={theme.colors.dark[7]}>{`${'85.2827'} ${userInfo.selectedCurrency.toUpperCase()}`}</Text>
                                </Group>
                                <Space h="lg" />
                                <Group position="apart">
                                    <Text
                                        size="md"
                                        weight={600}
                                        color={theme.colors.dark[7]}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        Liquid staked
                                        <Tooltip
                                            label={tooltipPhrase}
                                            wrapLines
                                            width={220}
                                            withArrow
                                            transition="fade"
                                            transitionDuration={200}
                                        >
                                            <ActionIcon>
                                                <InfoCircleIcon
                                                    size={16}
                                                    strokeWidth={2}
                                                    color={theme.colors.gray[6]}
                                                />
                                            </ActionIcon>
                                        </Tooltip>
                                    </Text>
                                    <div>
                                        <Text size="sm" align="right" weight={600} color={theme.colors.dark[7]}>
                                            {`${'38.3828'} ${userInfo.selectedCurrency.toUpperCase()}`}
                                        </Text>
                                        <Text size="sm" align="right" color={theme.colors.dark[7]}>
                                            {`in wallet as ${'39.38227'} ${'st' + userInfo.selectedCurrency.toUpperCase()}`}
                                        </Text>
                                    </div>
                                </Group>
                            </div>
                        </Accordion.Item>
                    </Accordion>
                </Paper>
            }
        </>

    );
};

export default WalletInfo;