import {MantineThemeOverride, Tuple} from '@mantine/core';

export const pinkOptions: Tuple<string, 10> = [
    '#FFF0F6',
    '#FFDEEB',
    '#FCC2D7',
    '#FAA2C1',
    '#F783AC',
    '#F06595',
    '#E50571',
    '#D6336C',
    '#C2255C',
    '#A61E4D',
];

export const cyanOptions: Tuple<string, 10> = [
    '#E9FCFF',
    '#C5F6FA',
    '#99E9F2',
    '#66D9E8',
    '#3BC9DB',
    '#22B8CF',
    '#15AABF',
    '#1098AD',
    '#0C8599',
    '#0B7285',
];

export const themeOptions = {
    defaultRadius: 'md',
    fontFamily: 'Inter',
    loader: 'oval',
    primaryColor: 'pink',
    primaryShade: {
        light: 6,
        dark: 8,
    },
    spacing: {
        xxs: 4,
        xs: 8,
        sm: 12,
        md: 16,
        lg: 24,
        xl: 32,
        xxl: 48,
    },
    colors: {
        pink: pinkOptions,
        cyan: cyanOptions,
    },
};