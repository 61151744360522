import React from 'react';
import {MantineProvider, ColorSchemeProvider, ColorScheme} from '@mantine/core';
import {useLocalStorageValue} from '@mantine/hooks';
import {NotificationsProvider, showNotification} from '@mantine/notifications';
import {Routes, Route} from 'react-router-dom';
import {getKeplrFromWindow} from '@keplr-wallet/stores';
import WalletConnect from '@walletconnect/client';
import {KeplrWalletConnectV1, WalletInfo, WalletManagerProvider} from 'cosmodal';

import {Header, Main} from './components';

import {EmbedChainInfos} from './config';
import {themeOptions} from './assets/constants/mantineTheme';


const walletInfoList: WalletInfo[] = [
    {
        id: 'keplr-wallet-extension',
        name: 'Keplr Wallet',
        description: 'Keplr Browser Extension',
        logoImgUrl: '/keplr-wallet-extension.png',
        getWallet: () => getKeplrFromWindow(),
    },
    {
        id: 'walletconnect-keplr',
        name: 'WalletConnect',
        description: 'Keplr Mobile',
        logoImgUrl: '/walletconnect-keplr.png',
        getWallet: (connector?: WalletConnect) =>
            Promise.resolve(
                connector
                    ? new KeplrWalletConnectV1(connector, EmbedChainInfos)
                    : undefined
            )
    },
];

const App = () => {
    // Switch Dark Mode
    const [colorScheme, setColorScheme] = useLocalStorageValue<ColorScheme>({
        key: 'mantine-color-scheme',
        defaultValue: 'light',
    });
    const toggleColorScheme = (value?: ColorScheme) => setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));
    // useHotkeys([['mod+J', () => toggleColorScheme()]]);

    // Stride setup connection
    const currentChain = EmbedChainInfos[0];

    window.onload = async () => {
        // @ts-ignore
        if (!window.getOfflineSigner || !window.keplr) {
            showNotification({
                title: 'Please install keplr extension!',
                message: '',
                color: 'red',
            });
        }
        else {
            // @ts-ignore
            if (window.keplr.experimentalSuggestChain) {
                // @ts-ignore
                try {
                    // @ts-ignore
                    await window.keplr.experimentalSuggestChain(currentChain);
                } catch {
                    showNotification({
                        title: 'Failed to suggest the chain!',
                        message: '',
                        color: 'red',
                    });
                }
            } else {
                showNotification({
                    title: 'Please use the recent version of keplr extension!',
                    message: '',
                    color: 'red',
                });
            }
        }
    }

    return (
        <div className="App">
            <WalletManagerProvider walletInfoList={walletInfoList}>
                <ColorSchemeProvider colorScheme='light' toggleColorScheme={toggleColorScheme}>
                    <MantineProvider
                        // @ts-ignore
                        theme={{...themeOptions}}
                        withGlobalStyles
                        withNormalizeCSS
                        styles={{
                            Paper: (theme) => ({
                                root: {
                                    backgroundColor: '#FFFFFF',
                                    borderColor: theme.colors.gray[2],
                                }
                            })
                        }}
                    >
                        <NotificationsProvider>
                            <div className="container">
                                <Header />
                                <Routes>
                                    <Route path='/' element={<Main />}/>
                                </Routes>
                            </div>
                        </NotificationsProvider>
                    </MantineProvider>
                </ColorSchemeProvider>
            </WalletManagerProvider>
        </div>
    );
};

export default App;
