import React from 'react';
import {Button, Group, Space, Text} from '@mantine/core';

export const UnstakeModalOptions = (selectedCurrency, closeModal, theme, nextStep) => {
    return [
        {
            id: -1,
            label: 'In a rush? Unstaking will take about 3 weeks to complete.',
            description: '',
            additionalComponents: function() {
                return (
                    <>
                        <Text size="sm">
                            {`If you’d like access to your ${selectedCurrency.toUpperCase()} sooner, you can trade ${'st' + selectedCurrency.toUpperCase()} directly on a DEX like Osmosis.`} <a href="#" target="_blank" style={{textDecoration: "none", color: theme.colors.pink[6]}}>Learn more</a>
                        </Text>
                        <Space h="lg" />
                        <Group>
                            <Button
                                variant="outline"
                                color="dark"
                                onClick={() => closeModal(false)}
                            >
                                Cancel
                            </Button>
                            <Button onClick={() => nextStep()}>
                                Continue unstake
                            </Button>
                        </Group>
                    </>
                )
            },

        },
        {
            id: 0,
            label: 'Confirm the transaction in your wallet to continue',
            description: `This will start the transfer of your ${'st' + selectedCurrency.toUpperCase()} tokens out of your wallet and start the unstaking process`
        },
        {
            id: 1,
            label: 'Unstaking your tokens...',
            description: 'Just a few seconds, unless the network is congested'
        },
        {
            id: 2,
            label: 'Unstake complete! Now for the unbonding...',
            description: 'You can leave Stride and return to claim your tokens once unbonding is complete. Connect your wallet to Stride anytime to see the status of this process',
            additionalComponents: function() {
                return (
                    <>
                        <Space h="lg" />
                        <Button
                            onClick={() => nextStep()}
                        >
                            Got it
                        </Button>
                    </>
                )
            },
        },
        {
            id: 3,
            label: 'Approve transaction in your wallet to claim your tokens',
            description: `This will transfer ${33.289} ${selectedCurrency.toUpperCase()} back to your wallet and the unstaking process will be complete.`
        },
        {
            id: 4,
            label: 'Claim successful!',
            description: `You’ve successfully unstaked ${34.122} ${'st' + selectedCurrency.toUpperCase()} and ${33.289} ${selectedCurrency.toUpperCase()} has been added to your wallet`,
            additionalComponents: function() {
                return (
                    <>
                        <Space h="lg" />
                        <Button
                            onClick={() => closeModal(false)}
                        >
                            Done
                        </Button>
                    </>
                )
            },
        },
    ]
};