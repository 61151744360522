import React from 'react';
import {Button, Space} from '@mantine/core';

export const LiquidStakeModalOptions = (selectedCurrency, closeModal) => {
    return [
        {
            id: 0,
            label: 'Approve the transaction in your  wallet to continue',
            description: `This will start the transfer of your ${selectedCurrency.toUpperCase()} tokens to Stride to start the staking process.`
        },
        {
            id: 1,
            label: `Transferring your ${selectedCurrency.toUpperCase()} to the Stride app...`,
            description: 'This could take 30 seconds or longer if the network is congested'
        },
        {
            id: 2,
            label: `Transfer complete! Approve in your wallet again to start staking your ${selectedCurrency.toUpperCase()}`,
            description: `This will initiate staking and the transfer of ${'st' + selectedCurrency.toUpperCase()} to your wallet`
        },
        {
            id: 3,
            label: `Staking your ${selectedCurrency.toUpperCase()}...`,
            description: 'Just a few seconds, unless the network is congested'
        },
        {
            id: 4,
            label: 'Success!',
            description: `You staked ${83.274} ${selectedCurrency.toUpperCase()} on Stride and ${84.289} ${'st' + selectedCurrency.toUpperCase()} has been added to your wallet`,
            additionalComponents: function() {
                return (
                    <>
                        <Space h="lg" />
                        <Button onClick={() => closeModal(false)}>
                            Great! Back to Stride
                        </Button>
                    </>
                )
            },
        },
    ]
};