import React, {FC, useState} from 'react';
import {Accordion, ActionIcon, Space, Group, Paper, Text, Button, useMantineTheme} from '@mantine/core';
import {Check as CheckIcon, ArrowsExchange as ArrowsExchangeIcon} from 'tabler-icons-react';

import {AccordionOptions} from '../../assets/constants/AccordionOptions';
import {RootState, useAppDispatch, useAppSelector} from '../../store';
import {EmbedChainInfos} from '../../config';
import IStatus from './Status.type';

const Status: FC<IStatus> = () => {
    const theme = useMantineTheme();
    const [status, setStatus] = useState<'nothing' | 'loading' | 'ready'>('nothing');

    const dispatch = useAppDispatch();
    const userInfo = useAppSelector((state: RootState) => state.user);

    const currentChain = EmbedChainInfos[0];

    return (
        <>
            { status === 'loading' &&
                <Paper p="lg" withBorder>
                    <Accordion
                        iconPosition="right"
                        transitionDuration={200}
                        // @ts-ignore
                        styles={AccordionOptions()}
                    >
                        <Accordion.Item label={
                            <Group spacing="md">
                                <ActionIcon
                                    size="lg"
                                    sx={{
                                        backgroundColor: theme.colors.yellow[6],
                                        '&:hover': {
                                            backgroundColor: theme.colors.yellow[6],
                                        }
                                    }}
                                >
                                    <ArrowsExchangeIcon
                                        size={16}
                                        strokeWidth={1}
                                        color={theme.colors.yellow[0]}
                                    />
                                </ActionIcon>
                                <Text size="md" weight={600}>Unbonding in progress...</Text>
                            </Group>
                        } >
                            <div>
                                <Group position="apart">
                                    <Text size="md" color={theme.colors.gray[7]}>Amount</Text>
                                    <Text size="sm" color={theme.colors.gray[7]}>{`${33.289} ${userInfo.selectedCurrency.toUpperCase()}`}</Text>
                                </Group>
                                <Space h="md" />
                                <Group position="apart">
                                    <Text size="md" color={theme.colors.gray[7]}>Start date</Text>
                                    <Text size="sm" color={theme.colors.gray[7]}>{`${'Jan 3 2022'}`}</Text>
                                </Group>
                                <Space h="md" />
                                <Group position="apart">
                                    <Text size="md" color={theme.colors.gray[7]}>Estimated complete date</Text>
                                    <Text size="sm" color={theme.colors.gray[7]}>{`${'Jan 24 - Jan 27'}`}</Text>
                                </Group>
                            </div>
                        </Accordion.Item>
                    </Accordion>
                </Paper>
            }

            { status === 'ready' &&
                <Paper p="lg" withBorder>
                    <Group position="apart">
                        <Group spacing="md">
                            <ActionIcon
                                size="lg"
                                variant="filled"
                                sx={{
                                    backgroundColor: theme.colors.cyan[6],
                                    '&:hover': {
                                        backgroundColor: theme.colors.cyan[6],
                                    }
                                }}
                            >
                                <CheckIcon
                                    size={16}
                                    strokeWidth={1}
                                    color="white"
                                />
                            </ActionIcon>
                            <div>
                                <Text size="md" weight={600}>
                                    {`${userInfo.selectedCurrency.toUpperCase()} ready to claim`}
                                </Text>
                            </div>
                        </Group>
                        <Button
                            variant="outline"
                            color="dark"
                        >
                            Claim tokens
                        </Button>
                    </Group>
                    <Space h="lg" />
                    <div>
                        <Group position="apart">
                            <Text size="md" color={theme.colors.gray[7]}>Amount</Text>
                            <Text size="sm" color={theme.colors.gray[7]}>{`${33.289} ${userInfo.selectedCurrency.toUpperCase()}`}</Text>
                        </Group>
                        <Space h="md" />
                        <Group position="apart">
                            <Text size="md" color={theme.colors.gray[7]}>Start date</Text>
                            <Text size="sm" color={theme.colors.gray[7]}>{`${'Jan 3 2022'}`}</Text>
                        </Group>
                        <Space h="md" />
                        <Group position="apart">
                            <Text size="md" color={theme.colors.gray[7]}>Estimated complete date</Text>
                            <Text size="sm" color={theme.colors.gray[7]}>{`${'Jan 26 2022'}`}</Text>
                        </Group>
                    </div>
                </Paper>
            }
        </>
    );
};

export default Status;