import React, {FC, useEffect, useState} from 'react';
import {Button, Divider, Group, Paper, SegmentedControl, Space, Text, TextInput, useMantineTheme} from '@mantine/core';

import {LiquidStakeModal, UnstakeModal} from '../index';

import {RootState, useAppDispatch, useAppSelector} from '../../store';
import {EmbedChainInfos} from '../../config';
import IStake from './Stake.type';

const AUTO_CONNECT_WALLET_KEY = 'auto_connect_wallet';

interface ErrorType {
    status: boolean,
    message: string,
}

const Stake: FC<IStake> = () => {
    const theme = useMantineTheme();

    const dispatch = useAppDispatch();
    const userInfo = useAppSelector((state: RootState) => state.user);
    const currentChain = EmbedChainInfos[0];

    const [controlValue, setControlValue] = useState<string>('stake');
    const [isOpenedModal, setIsOpenedModal] = useState<boolean>(false);

    const [value, setValue] = useState<string | number>(0);
    const [error, setError] = useState<ErrorType>({status: false, message: 'Amount is required'});

    // const handleSubmit = () => {
    //     if (userInfo.address) {
    //         if (value === '') {
    //             setError({ status: true, message: 'Amount is required' });
    //         } else {
    //             submitForm(+value, currentChain, getWallet)
    //                 .then((amount) => {
    //                     if (amount) {
    //                         dispatch(updateCurrencyAmount(amount));
    //                     } else {
    //                         console.log('Error!');
    //                     }
    //                 });
    //         }
    //     } else {
    //         connectWallet(getWallet, currentChain)
    //             .then((value) => dispatch(updateUserInfo(value)));
    //     }
    // }

    useEffect(() => {
        if (value) {
            if (isNaN(+value)) {
                setError({ status: true, message: 'Amount must be a number' });
            } else if (+value === 0) {
                setError({ status: true, message: 'Amount must be greater than 0' });
            } else {
                setError({ status: false, message: '' });
            }
        } else {
            setError({ status: false, message: '' });
        }
    }, [value]);


    return (
        <Paper
            p="xxl"
            withBorder
            radius="md"
            shadow="md"
        >
            <SegmentedControl
                value={controlValue}
                onChange={(value) => setControlValue(value)}
                data={[
                    { label: 'Stake', value: 'stake' },
                    { label: 'Unstake', value: 'unstake' },
                ]}
                fullWidth
                size="md"
                radius="xl"
            />
            <Space h="sm" />
            <div className="stakeForm">
                <Text size="sm" align="center">
                    { controlValue === 'stake'
                        ? `Stake your ${userInfo.selectedCurrency.toUpperCase()} tokens in exchange for ${'st' + userInfo.selectedCurrency.toUpperCase()} which you can deploy around the ecosystem.`
                        : `Unstake your ${'st' + userInfo.selectedCurrency.toUpperCase()} tokens in exchange for ${userInfo.selectedCurrency.toUpperCase()}.`
                    }
                </Text>
                <Space h="xl" />
                <Text size="sm">
                    { controlValue === 'stake'
                        ? `Amount to stake:`
                        : `Amount to unstake:`
                    }
                </Text>
                <Text size="lg" weight={700}>
                    { controlValue === 'stake' ? `${userInfo.selectedCurrency.toUpperCase()}` : `${'st' + userInfo.selectedCurrency.toUpperCase()}` }
                </Text>
                <Space h="md" />
                <TextInput
                    className="mantine-TextInput-input"
                    radius="md"
                    size="xl"
                    value={value}
                    onChange={(event) => setValue(event.currentTarget.value)}
                />
                <Space h="xs" />
                { userInfo.address &&
                    <Group position="apart">
                        <Text size="sm">
                            Available in wallet: {controlValue === 'stake' ? `${85.2827} ${userInfo.selectedCurrency.toUpperCase()}` : `${34.122} ${'st' + userInfo.selectedCurrency.toUpperCase()}`}
                        </Text>
                        <Text
                            size="sm"
                            color={theme.colors.pink[7]}
                            sx={{
                                cursor: 'pointer',
                                userSelect: 'none',
                                msUserSelect: 'none',
                            }}
                            onClick={() => setValue(100)}
                        >
                            Add max
                        </Text>
                    </Group>
                }
                <Space h="xl" />
                <Divider />
                <Space h="xl" />
                <Group position="apart">
                    <div>
                        <Text size="sm">What you’ll get:</Text>
                        <Text size="lg" weight={700}>
                            { controlValue === 'stake' ? `${'st' + userInfo.selectedCurrency.toUpperCase()}` : `${userInfo.selectedCurrency.toUpperCase()}` }
                        </Text>
                    </div>
                    <Text
                        weight={700}
                        color={theme.colors.gray[7]}
                        sx={{
                            fontSize: '26px',
                            paddingRight: '20px'
                        }}
                    >
                        {0}
                    </Text>
                </Group>
                <Space
                    // @ts-ignore
                    h="xxl"
                />
                <Button
                    size="lg"
                    fullWidth
                    disabled={error.status}
                    onClick={() => setIsOpenedModal(true)}
                >
                    {controlValue === 'stake' ? 'Liquid stake' : 'Unstake'}
                </Button>
            </div>

            { controlValue === 'stake'
                ? <LiquidStakeModal isOpened={isOpenedModal} closeModal={setIsOpenedModal}  />
                : <UnstakeModal isOpened={isOpenedModal} closeModal={setIsOpenedModal}  />
            }
        </Paper>
    );
};

export default Stake;